/* * {
  border: 1px solid black;
} */

@font-face {
    font-family: "Avenir";
    src: url("./assests//fonts/AvenirLTStd-Black.otf");
}

.marginTop30 {
    margin-top: 30px;
}

.marginbtn {
    margin-right: 1px !important;
}

.toolTipLabel0 {
    color: white !important;
    background-color: green !important;
    width: 10%;
}

.toolTipLabel1 {
    color: black !important;
    background-color: lightgreen !important;
    width: 10%;
}

.toolTipLabel2 {
    color: black !important;
    width: 10%;
    background-color: yellow !important;
}

.toolTipLabel3 {
    width: 10%;
    color: black !important;
    background-color: #FF8080 !important;
}

.toolTipLabel4 {
    color: white !important;
    width: 10%;
    background-color: #FF0000 !important;
}

.autoMargin {
    transform: translateY(90%);
    border: 2px solid white;
    height: 16px;
    width: 16px;
    vertical-align: baseline;
    margin-top: -5px;
}

.green_band {
    text-align: center;
    border: 1px dashed #89bc3b;
    background: #f3fbe8;
    /* padding-right: 30px; 
    padding-bottom: 10px;*/
    border-radius: 10px;
    padding: 0px 0px 10px 5px;
}

.labelMargin {
    transform: translateY(59%);
    font-size: 14px;
    margin-right: 15px;
}

.sa_risk {
    margin-bottom: 0px;
    padding-top: 10px;
}

.risk_rating {
    font-size: 1.6rem;
    font-weight: 400;
}

.colorBg {
    background-color: lightgray;
}

.autoMargin:focus {
    border-color: white;
    outline: white;
}

.marginTop1 {
    margin-top: 1%;
}

.marginTop2 {
    margin-top: 2%;
}

.marginTop3 {
    margin-top: 10%;
}

.marginBottom1 {
    margin-bottom: 1%;
}

.hIeTPA:not(:last-of-type) {
    min-height: 38px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
}

.clientSearchButton {
    width: 49%;
}

.hoverforget {
    cursor: pointer;
}

.rdtPicker {
    right: 0;
}

@media only screen and (max-width: 1200px) {
    .clientSearchButton {
        width: 39%;
    }
}

.eaeNpf {
    border-right-color: #eeeeee;
    border-right-style: solid;
    border-right-width: 1px;
}

.bMDXVo {
    border-left-color: #eeeeee;
    border-left-style: solid;
    border-left-width: 1px;
}

.navbar-green {
    background-color: #89bc3b;
}

.btn-success {
    color: #fff;
    background-color: #89bc3b;
    border-color: #89bc3b;
    box-shadow: none;
}

.btn-success:hover {
    color: #fff;
    background-color: #7aa835;
    border-color: #2bb94c;
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #89bc3b;
    border-color: #89bc3b;
}

.text-green {
    color: #89bc3b;
}

.btn-primary {
    color: #fff;
    background-color: #2aabe4;
    border-color: #2aabe4;
    box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #2aabe4;
    border-color: #2aabe4;
}

.nav-pills .nav-link:not(.active):hover {
    color: #2aabe4;
}

.btn-primary:hover {
    color: #fff;
    background-color: #2598cb;
    border-color: #2598cb;
}

a {
    color: #2aabe4;
}

a:hover {
    color: #2598cb;
}

.guAivK {
    font-weight: bold;
    font-size: 0.9rem;
}

.notes_dashboard_section {
    margin: -1.25rem -1.25rem;
}

.mid-center {
    top: 45%;
    left: 50%;
    margin: 0 0 0 -150px;
}

.line-green {
    border-top: 1px solid #89bc3b;
    width: 125px;
    display: table-cell;
    margin-top: 11px;
    float: left;
    margin-left: 5px;
}

.dcarissidenav {
    opacity: 2 !important;
    visibility: visible !important;
}

.modalHeader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modalFooter {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.modal---modal-content---3pwnC .modal---close---28rLZ {
    top: 25px;
}

.dashboard .fa,
.dashboard .fas,
.dashboard .far {
    font-weight: 900;
    margin-right: 4px;
}

.dashboard .p-0 {
    padding: 0 !important;
    margin: -2px;
    min-height: 295px;
}


/*.dashboard-table {
      margin: 0px 0px 0px 5px;
      width: 98% !important;
  }*/

.nav .nav-treeview .has-treeview .active {
    display: block;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #89bc3b;
    background-color: #fff;
}

.sidenav .text-green {
    position: relative;
    color: #89bc3b;
    margin-bottom: 10px;
}

.sidenav .text-green span {
    padding-right: 10px;
    z-index: 1;
    background: #f4f6f9;
    position: relative;
}

.lead {
    font-size: 1.45rem;
}

.nav-link i {
    font-size: 0.875rem !important;
}

.sidenav .text-green:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.7em;
    border-top: 1px solid #89bc3b;
    width: 80%;
}

.nav-treeview ul li {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.rdtOpen .rdtPicker {
    display: block;
    border-style: solid;
    border-color: #aeaeae;
}

.fup-details-date {
    width: 103%;
}

.modal-body #schedule_date {
    width: 117%;
}

.linkpadding {
    padding-top: 0.5rem;
}

.content-wrapper>.content {
    padding: 0 0.5rem;
}

.dob_client_search {
    width: 330px;
}

.react-datepicker-wrapper,
.react-bootstrap-daterangepicker-container {
    width: calc(100% - 40px);
}

.datetimepicker_custom {
    width: calc(100% - 42px);
}

.modal-body {
    padding: 0px;
}

.modal---modal-content---3pwnC .modal---modal-footer---20LPi {
    min-height: 56px;
    margin-top: 0;
    text-align: right;
    border-top: 1px solid #ccc;
    background-color: none !important;
}

.boldText {
    font-weight: bold;
}

tfoot {
    background-color: transparent !important;
    text-align: center !important;
}

.pointer {
    color: #adb5bd;
    cursor: pointer;
}

.pointer:hover {
    color: #495057;
}

.text-accordain {
    color: #2aabe4;
    font-size: 1rem;
}

.btn-green {
    color: #fff;
    background-color: #2ecc71;
}

.input-with-icon {
    /* causes absolute icon div to be positioned correctly */
    position: relative;
    box-sizing: border-box;
}

.input-with-icon .icon {
    position: absolute;
    right: 0rem;
    top: 0rem;
    width: 2.6rem;
    height: 100%;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.modal---modal-content---3pwnC .modal---modal-title---2JFyt {
    font-weight: inherit;
    letter-spacing: inherit;
    font-size: inherit;
}

.modal---modal-content---3pwnC * {
    overflow-wrap: break-word;
}

.modal---modal-content---3pwnC .modal---modal-footer---20LPi button {
    min-width: inherit;
}

.hTWLvd div {
    color: #35a745 !important;
}


/*.tnaXA{display:block !important;}*/

#divLoader {
    top: 35%;
    left: 43%;
    position: fixed;
    opacity: 1;
    z-index: 999999;
    height: 100%;
    width: 100%;
    margin: auto;
}

.rdt_TableHeader {
    display: none !important;
}

#loader_overlay {
    top: 0%;
    left: 0%;
    position: fixed;
    opacity: 0.8;
    z-index: 999999;
    height: 100%;
    width: 100%;
    margin: auto;
    background: #cccccc7d;
}

.dropzone {
    text-align: center;
    padding: 20px;
    outline: 3px dashed #eeeeee;
    color: #bdbdbd;
}

.dropzone:focus {
    outline-color: #94a397;
}

.file-upload-message {
    position: relative;
    top: 25px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 1.2rem;
}

.e-sign {
    padding: 30px;
    border: 2px solid #898181;
    margin-bottom: 45px;
}

.modal-dialog {
    pointer-events: all;
}

.table-center th,
.table-center td:not(:first-child) {
    text-align: center;
}


/*.table-center th{
  vertical-align: middle !important;
  }*/

.modal-full {
    max-width: 95%;
}

p li {
    margin-left: 2px;
}

.no_record_found {
    box-sizing: border-box;
    background-color: #89bc3b;
    color: #ffffff;
    padding: 25px 0px;
    width: 100%;
    text-align: center;
    margin: auto;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.custom_alert_msg_ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #28bae6;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
}

.custom_alert_msg_ui>h1 {
    margin-top: 0;
}

.custom_alert_msg_ui>button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}

.health_screening_errors {
    border: 1px solid #dc3545;
    border-left-width: 6px;
}

#phq9form label,
#gadscreening label,
#intakebasicinfoform label {
    display: inline;
}

.invoice {
    height: 90%;
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@page {
    size: auto;
    margin: 20mm 6mm;
}

@media (min-width: 768px) {
    input[type="number"]::-webkit-inner-spin-button {
        width: 1.5rem;
        height: 1.5rem;
        background-color: transparent;
    }
}

@media (max-width: 576px) {
    .modal-dialog {
        max-width: 80%;
        min-width: 80%;
        margin: 1.75rem auto;
    }
}

@media (max-width: 375px) {
    .modal-dialog {
        max-width: 80%;
        margin: 1.75rem auto;
        min-width: 80%;
        left: -5%;
        right: 5%;
    }
}

.accordain-heading {
    color: #89bc3b !important;
    font-size: 1.3rem !important;
}

.accordain-heading .fa-angle-down {
    float: right;
}

.accordain-heading h5 {
    margin-bottom: 0px;
    font-size: 1.15rem;
}


/*--- tab----*/

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    /* color: #1891c5;
    background-color: #F7FFEA; 
    border-bottom: 3px solid #89bc3b !important;*/
    border: none;
    color: #ffffff !important;
    background-color: #89bc3b;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover {
    color: #ffffff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    text-transform: uppercase;
    font-size: 0.75rem;
    text-align: center;
    color: #212529;
    /* background-color: #F7FFEA; */
}

form .lead {
    font-size: 1.05rem;
    line-height: 20px;
}

.table thead th {
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
}

.head-text {
    color: #1693c8;
}


/* .scroll-tabs{overflow-x: scroll;} */

.nav-tabs .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    /* border-color: #e9ecef #e9ecef #dee2e6; */
    border: 0px;
    color: #89bc3b;
}

.nav-tabs .nav-link {
    border: 0px;
}

.scroller {
    text-align: center;
    cursor: pointer;
    padding: 7px;
    padding-top: 13px;
    white-space: no-wrap;
    vertical-align: middle;
    background-color: #fff;
}

.list {
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.card-wrapper {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    padding: 5px;
    height: 50px;
}

.list li {
    white-space: nowrap;
}

.list::-webkit-scrollbar {
    height: 5px;
    /* background-color: lightgray; */
}

.list::-webkit-scrollbar-track {
    background: transparent;
}

.list::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-right: none;
    border-left: none;
}

.list::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
}

.list::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}

.heightScroll {
    max-height: 500px;
    overflow-y: scroll;
}

.image-upload-signature>input {
    visibility: hidden;
}


.homeContainerSearch input{
    border: #f7f7f7;
    background: #f7f7f7;
    padding-top: 20px;
    font-size: 14px;
}
.td1{
    width: 20%;
    overflow-y: hidden;
  }
  .td2{
    width: 20%;
  }
  .td3{
   width: 20%;
  }
  .td4{
      width: 10%;
  }
  .td5{
      width: 10%;
      
  }
  .td6{
      width: 10%;
      
  }
  
  .cd1{
      width: 20%;
    }
    .cd2{
      width: 20%;
    }
    .cd3{
     width: 20%;
    }
    .cd4{
        width: 20%;
    }
    .cd5{
        width: 20%;
        
    }
    
  