body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apexcharts-text tspan{
  font-size: 12.5px;
}
.dropdown-toggle.nav-link{
  color: rgba(255,255,255,.75);
  font-family: 
  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 19px;
    line-height: 24px;
  font-weight: 530;
}

.dropdown-toggle.nav-link:hover
{
  color: white;
 
}

.apexcharts-menu-icon{
  display: none;
}


/*  Authorization for ROI pdf design  start*/
@media screen {
  .screenOnly {
      display: block; 
  }
  .printOnly {
     float: right!important;
      display: none; 
  }
}
@media print {
  .screenOnly {
      display: none;
  }
  .printOnly {
    float: right!important;
    display: block; 
  }
}
.text-right {
  text-align: right !important;
}
.PdfBackgroundColorAuthorization{
  background-color: "#e9ecef";
  text-align: justify;
  width: 460px !important;
}
.heightRemoveAuthorization{
  height: auto !important;
  min-height: 40px !important;
  content: attr(data-content) "\A" ;
  white-space: pre-wrap !important;
}
/*  Authorization for ROI pdf design  end*/

/*  Hospital  ROI pdf design  start*/
.PdfBackgroundHospitalROI{
  background-color: "#e9ecef";
  text-align: justify;
}
/*  Hospital  ROI pdf design  end*/

/* state assessment pdf css */
.heightRemove{
  height: auto !important;
  min-height: 40px !important;
  content: attr(data-content) "\A" ;
  white-space: pre-wrap !important;
}
.PdfBackgroundColor{
  background-color: "#e9ecef";
  text-align: justify;
}
.paddingtop{
  padding-top: 10px !important;
}

/* updateAdhm in clientInformation due date design start*/
.due_Date_clientInformation{
  display: block;
  width: 114%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
/* updateAdhm in clientInformation due date design end*/